/*
$green: #57C525;
$gray-600: #556B85;
$yellow: #FACA00;
$orange: #FF7F00;
$info: $orange;
$blue: #00C4FF;
$red: #FF2B2B;

$alert-bg-scale: -25% !default;
$alert-border-scale: 0% !default;
$alert-color-scale: 40% !default;
*/

$white: #ffffff;

$theme-colors: (
    "light":      #dfdfe2,
    "dark":       #102636,
    "primary":    #293b59,
    "secondary":  #485159,
    "info":       #244b5c,
    "success":    #28965a,
    "warning":    #dec72c,
    "danger":     #ea1e17,
);

$border-radius:        .15rem;
$border-radius-sm:     .1rem;
$border-radius-lg:     .2rem;
$border-radius-pill:    50rem;

$accordion-button-color: #244b5c;