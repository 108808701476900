/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "variables";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@mixin font-face($family, $file, $path, $svg, $weight: normal, $style: normal){
  @font-face{
    font-family: $family;
    src: url($path + $file + '.ttf');
    src: url($path + $file + '.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face('roboto', 'Roboto-Regular', 'assets/fonts/roboto/', 'roboto-regular');
@include font-face('roboto', 'Roboto-Medium', 'assets/fonts/roboto/', 'roboto-medium', 500, normal);
@include font-face('roboto', 'Roboto-Bold', 'assets/fonts/roboto/', 'roboto-bold', 700, normal);